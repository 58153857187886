(function ($) {
    "use strict";


    /*
    * ------------------------------------------------------------------------
    * Variables
    * ------------------------------------------------------------------------
    */

    var screenSm = 576,
        screenMd = 768,
        screenLg = 992,
        screenXl = 1200,
        $window = $(window);


    /*
    * ------------------------------------------------------------------------
    * Function definitions
    * ------------------------------------------------------------------------
    */

    //Set / Expire Cookies

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    /*
    * ------------------------------------------------------------------------
    * Runs when HTML is ready
    * ------------------------------------------------------------------------
    */

    $(function () {

        // Equalizes heights of blocks
        if ($.isFunction($.fn.matchHeight)) {
            $('.card').matchHeight({
                btRow: true
            });
        }

        // Main nav initialization
        $('#navMain').multilevelNav({
            'hoverIntent': true,
            'hoverIntentTimeout': 300,
            'toggleOnClickOnly': true,
            'topLevelWholeLinkToggler': true
		});

		//Hack to stop Episerver from rendering the wrong url when linking to other sites in the multisite
		
		$(".multi-site-link").each(function () {
			var href = $(this).attr('href');
			$(this).attr("href", href.replace("multisite", ""));
		});

        // Cookie Notification Cookie

        var cookie = getCookie('LurieCareersCookieNotification');
        if (!cookie) {
            $('#cookieNotification').show();
        }

        $("#cookieNotificationAccept").on("click", function () {
            var days = $(this).data("duration");
            if (days > 0) {
                setCookie('LurieCareersCookieNotification', true, days);
            }
            $('#cookieNotification').hide();
        });

    });


    /*
    * ------------------------------------------------------------------------
    * Run on window resize
    * ------------------------------------------------------------------------
    */

    var width = $(window).width();
    $(window).resize(function () {
        if ($(this).width() !== width) {

        }
    });

})(jQuery);
